<template>
  <div class="thank-you-page">
    <header>
      <nav>
        <div class="logo">dPing</div>
        <ul>
          <li><a href="/#about">About</a></li>
          <li><a href="/#features">Features</a></li>
          <li><a href="/#download">Download</a></li>
        </ul>
      </nav>
    </header>

    <main>
      <div class="thank-you">
        <h1>Thank You!</h1>
        <p>Your submission has been received. We'll be in touch soon.</p>
        <router-link to="/" class="cta-button">Back to Home</router-link>
      </div>
    </main>

    <footer>
      <div class="social-links">
        <a href="https://x.com/dping_io" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-x-twitter"></i>
        </a>
        <a href="https://t.me/dping_io" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-telegram"></i>
        </a>
      </div>
      <div class="footer-links">
        <a href="#">Privacy Policy</a>
        <a href="#">Terms of Service</a>
        <a href="#">Contact</a>
      </div>
      <p class="copyright">&copy; 2024 dPing. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPage'
}
</script>

<style scoped>
.thank-you-page {
  font-family: 'Roboto Mono', monospace;
  color: #333;
  background-color: #ffffff;
  line-height: 1.6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: #f0f0f0;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  color: #6200ea;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #6200ea;
}

main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.thank-you {
  text-align: center;
}

h1 {
  color: #6200ea;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 700;
}

p {
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.cta-button {
  background-color: #6200ea;
  color: #ffffff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  font-family: 'Roboto Mono', monospace;
  text-decoration: none;
  display: inline-block;
}

.cta-button:hover {
  background-color: #3700b3;
  transform: translateY(-2px);
}

footer {
  background-color: #f0f0f0;
  padding: 2rem 0;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.social-links a {
  color: #6200ea;
  font-size: 2.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
  color: #3700b3;
  transform: scale(1.1);
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.footer-links a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #6200ea;
}

.copyright {
  font-size: 0.9rem;
  color: #666;
}
</style>
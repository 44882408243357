<template>
  <div class="landing-page">
    <header>
      <nav>
        <div class="logo">dPing</div>
        <ul>
          <li><a href="#about">About</a></li>
          <li><a href="#features">Features</a></li>
          <li><a href="#download">Download</a></li>
        </ul>
      </nav>
    </header>

    <main>
      <section id="hero" class="hero-section">
        <div class="hero-content">
          <h1>Decentralized Internet Quality & Website Tracking</h1>
          <p>Monitor global internet quality and website availability. Earn tokens and contribute to a decentralized web.</p>
          <div class="button-group">
            <button class="cta-button" @click="showModal = true">Mine</button>
            <button class="cta-button" @click="showModal = true">Use</button>
          </div>
        </div>
        <div class="hero-image">
          <!-- Add a SVG or image representing global network here -->
          <img src="@/assets/dping-hero.webp" alt="Global Network Representation">
        </div>
      </section>

      <section id="world-map" class="world-map-section">
        <h2>Global dPing Network</h2>
        <div class="map-container">
          <ol-map :loadTilesWhileAnimating="true" :loadTilesWhileInteracting="true" style="height: 500px">
            <ol-view :center="mapCenter" :rotation="0" :zoom="2" :projection="mapProjection" />
            
            <ol-tile-layer>
              <ol-source-osm />
            </ol-tile-layer>

            <ol-interaction-clusterselect
              @select="featureSelected"
              :pointRadius="20"
              :featureStyle="featureStyle"
            >
              <ol-style>
                <ol-style-icon :src="markerIcon" :scale="0.05"></ol-style-icon>
              </ol-style>
            </ol-interaction-clusterselect>

            <ol-animated-clusterlayer :animationDuration="500" :distance="40">
              <ol-source-vector>
                <ol-feature v-for="point in minerPoints" :key="point.id">
                  <ol-geom-point :coordinates="point.coordinates"></ol-geom-point>
                </ol-feature>
              </ol-source-vector>

              <ol-style :overrideStyleFunction="overrideStyleFunction">
                <ol-style-stroke color="red" :width="2"></ol-style-stroke>
                <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>

                <ol-style-circle :radius="20">
                  <ol-style-stroke
                    color="black"
                    :width="15"
                    :lineDash="[]"
                    lineCap="butt"
                  ></ol-style-stroke>
                  <ol-style-fill color="black"></ol-style-fill>
                </ol-style-circle>

                <ol-style-text>
                  <ol-style-fill color="white"></ol-style-fill>
                </ol-style-text>
              </ol-style>
            </ol-animated-clusterlayer>
          </ol-map>
        </div>
      </section>

      <section id="about" class="about-section">
        <h2>Revolutionizing Internet Metrics</h2>
        <div class="about-grid">
          <div class="about-item">
            <i class="fas fa-globe"></i>
            <h3>Global Coverage</h3>
            <p>Measure internet quality from nodes worldwide</p>
          </div>
          <div class="about-item">
            <i class="fas fa-coins"></i>
            <h3>Token Rewards</h3>
            <p>Earn DPING tokens for your contributions</p>
          </div>
          <div class="about-item">
            <i class="fas fa-chart-line"></i>
            <h3>Real-time Data</h3>
            <p>Access up-to-the-minute internet performance metrics</p>
          </div>
        </div>
      </section>

      <section id="features" class="features-section">
        <h2>Key Features</h2>
        <div class="feature-grid">
          <div class="feature-item">
            <i class="fas fa-network-wired"></i>
            <h3>Decentralized Network</h3>
            <p>Measurement nodes distributed globally</p>
          </div>
          <div class="feature-item">
            <i class="fas fa-tachometer-alt"></i>
            <h3>Performance Tests</h3>
            <p>Ping, traceroute, and HTTP tests</p>
          </div>
          <div class="feature-item">
            <i class="fas fa-coins"></i>
            <h3>Token Incentives</h3>
            <p>Rewards for network participants</p>
          </div>
          <div class="feature-item">
            <i class="fas fa-code"></i>
            <h3>API Access</h3>
            <p>For developers and researchers</p>
          </div>
        </div>
      </section>

      <section id="download" class="mining-options-section">
        <h2>Start Mining dPing Tokens</h2>
        <div class="mining-options">
          <div class="mining-option software">
            <h3>Software Miner</h3>
            <p>Download and install for free to start earning tokens. Available as:</p>
            <ul>
              <li>Docker images</li>
              <li>Ubuntu packages</li>
              <li>CentOS packages</li>
              <li>Debian packages</li>
              <li>Raspbian packages</li>
              <li>OpenWRT packages</li>
            </ul>
            <button class="cta-button" @click="showModal = true">Download Software Miner</button>
          </div>
          <div class="mining-option hardware">
            <h3>Hardware Miner</h3>
            <img src="@/assets/hardware-miner.webp" alt="dPing Hardware Miner" class="hardware-image">
            <p>Order a dedicated hardware miner for $50. Generate more tokens compared to software probes.</p>
            <button class="cta-button" @click="showModal = true">Order Hardware Miner</button>
          </div>
        </div>
      </section>
    </main>

    <footer>
      <div class="social-links">
        <a href="https://x.com/dping_io" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-x-twitter"></i>
        </a>
        <a href="https://t.me/dping_io" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-telegram"></i>
        </a>
      </div>
      <div class="footer-links">
        <a href="#">Privacy Policy</a>
        <a href="#">Terms of Service</a>
        <a href="#">Contact</a>
      </div>
      <p class="copyright">&copy; 2024 dPing. All rights reserved.</p>
    </footer>

    <!-- Modal for collecting contact information -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h2>Join dPing Network Waitlist</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" id="name" name="name" v-model="formData.name" required>
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" name="email" v-model="formData.email" required>
          </div>
          <button type="submit" class="submit-button" :disabled="isSubmitting">
            {{ isSubmitting ? 'Submitting...' : 'Join Waitlist' }}
          </button>
          <button type="button" class="close-button" @click="showModal = false">Close</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useGeographic } from 'ol/proj';
import { Style, Stroke, Circle, Fill } from 'ol/style';
import { useRouter } from 'vue-router';

// Import your marker icon
import markerIcon from '@/assets/marker.png';

export default {
  name: 'LandingPage',
  setup() {
    useGeographic();
    
    const mapCenter = ref([0, 0]);
    const mapProjection = ref('EPSG:4326');
    
    const minerPoints = ref([
      { id: 1, coordinates: [-122.4194, 37.7749] }, // San Francisco
      { id: 2, coordinates: [-0.1276, 51.5074] },   // London
      { id: 3, coordinates: [139.6917, 35.6895] },  // Tokyo
      { id: 4, coordinates: [8.6821, 50.1109] },    // Frankfurt
      { id: 5, coordinates: [4.9041, 52.3676] },    // Amsterdam
      { id: 6, coordinates: [103.8198, 1.3521] },   // Singapore
      { id: 7, coordinates: [106.8456, -6.2088] },  // Jakarta
      { id: 8, coordinates: [120.9842, 14.5995] },  // Manila
      { id: 9, coordinates: [72.8777, 19.0760] },   // Mumbai
      { id: 10, coordinates: [28.0473, -26.2041] }, // Johannesburg
      { id: 11, coordinates: [-46.6333, -23.5505] },// Sao Paulo
      { id: 12, coordinates: [-74.0060, 40.7128] }, // New York
      { id: 13, coordinates: [-77.0369, 38.9072] }, // Washington
      { id: 14, coordinates: [-80.1918, 25.7617] }, // Miami
      { id: 15, coordinates: [-99.1332, 19.4326] }, // Mexico City
      { id: 16, coordinates: [55.2708, 25.2048] },  // Dubai
      { id: 17, coordinates: [3.3792, 6.5244] },    // Lagos
      { id: 18, coordinates: [12.4964, 41.9028] },  // Rome
      { id: 19, coordinates: [100.5018, 13.7563] }, // Bangkok
      { id: 20, coordinates: [114.1694, 22.3193] }, // Hong Kong
      { id: 21, coordinates: [151.2093, -33.8688] },// Sydney
      { id: 22, coordinates: [105.8342, 21.0278] }, // Hanoi
      { id: 23, coordinates: [24.9384, 60.1699] },  // Helsinki
      { id: 24, coordinates: [10.7522, 59.9139] },  // Oslo
      { id: 25, coordinates: [76.9286, 43.2220] },  // Alma-Aty
      { id: 26, coordinates: [30.5234, 50.4501] },  // Kyiv
      { id: 27, coordinates: [2.3522, 48.8566] },   // Paris
      { id: 28, coordinates: [-3.7038, 40.4168] },  // Madrid
      { id: 29, coordinates: [21.0122, 52.2297] },  // Warsaw
      { id: 30, coordinates: [-79.5187, 8.9824] },  // Panama City
    ]);

    const featureStyle = () => {
      return [
        new Style({
          stroke: new Stroke({
            color: '#ab34c4',
            width: 2,
            lineDash: [5, 5],
          }),
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: '#ab34c4',
              width: 1,
            }),
            fill: new Fill({
              color: '#ab34c444',
            }),
          }),
        }),
      ];
    };

    const overrideStyleFunction = (feature, style) => {
      const clusteredFeatures = feature.get('features');
      const size = clusteredFeatures.length;

      const color = size > 20 ? '192,0,0' : size > 8 ? '255,128,0' : '171, 52, 196';
      const radius = Math.max(8, Math.min(size, 20));
      const dash = (2 * Math.PI * radius) / 6;
      const calculatedDash = [0, dash, dash, dash, dash, dash, dash];

      style.getImage().getStroke().setLineDash(dash);
      style.getImage().getStroke().setColor(`rgba(${color},0.5)`);
      style.getImage().getStroke().setLineDash(calculatedDash);
      style.getImage().getFill().setColor(`rgba(${color},1)`);

      style.getImage().setRadius(radius);

      style.getText().setText(size.toString());
      return style;
    };

    const featureSelected = (event) => {
      console.log('Feature selected:', event);
    };

    const router = useRouter();

    return {
      mapCenter,
      mapProjection,
      minerPoints,
      markerIcon,
      featureStyle,
      overrideStyleFunction,
      featureSelected,
      router,
    };
  },
  data() {
    return {
      showModal: false,
      formData: {
        name: '',
        email: ''
      },
      formEndpoint: 'https://getform.io/f/bmdpjqka', // Your Getform.io endpoint
      isSubmitting: false,
    }
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true;
      try {
        const response = await fetch(this.formEndpoint, {
          method: 'POST',
          body: JSON.stringify(this.formData),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        
        if (response.ok) {
          // Close the modal if it's open
          this.showModal = false;
          // Reset form data
          this.formData = { name: '', email: '' };
          // Redirect to the thank you page
          this.router.push('/thank-you');
        } else {
          throw new Error('Form submission failed');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('There was an error submitting the form. Please try again later.');
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>

<style scoped>
.landing-page {
  font-family: 'Roboto Mono', monospace;
  color: #333;
  background-color: #ffffff;
  line-height: 1.6;
}

header {
  background-color: #f0f0f0;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  color: #6200ea;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #6200ea;
}

.hero-section {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 80px);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-content {
  flex: 1;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.hero-image {
  flex: 1;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}

h1 {
  color: #6200ea;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  color: #6200ea;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.cta-button, .download-button, .submit-button {
  background-color: #6200ea;
  color: #ffffff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  font-family: 'Roboto Mono', monospace;
}

.cta-button:hover, .download-button:hover, .submit-button:hover {
  background-color: #3700b3;
  transform: translateY(-2px);
}

.cta-button.secondary {
  background-color: #ffffff;
  color: #6200ea;
  border: 2px solid #6200ea;
}

.cta-button.secondary:hover {
  background-color: #f0f0f0;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  border-radius: 5px;
  font-family: 'Roboto Mono', monospace;
}

.close-button {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 0.8rem 1.5rem;
  margin-left: 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Roboto Mono', monospace;
}

.close-button:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-content {
    padding-right: 0;
    margin-bottom: 2rem;
    align-items: center;
  }
}

.world-map-section {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
}

.map-container {
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .world-map-section {
    padding: 2rem 1rem;
  }
}

.features-section {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-item {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.feature-item i {
  font-size: 2.5rem;
  color: #6200ea;
  margin-bottom: 1rem;
}

.feature-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .features-section {
    padding: 2rem 1rem;
  }
  
  .feature-grid {
    grid-template-columns: 1fr;
  }
}

.mining-options-section {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
}

.mining-options {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
}

.mining-option {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.mining-option h3 {
  color: #6200ea;
  margin-bottom: 1rem;
}

.mining-option ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1rem;
}

.mining-option ul li {
  margin-bottom: 0.5rem;
}

.mining-option ul li::before {
  content: "•";
  color: #6200ea;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.hardware-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.cta-button {
  margin-top: auto;
}

@media (max-width: 768px) {
  .mining-options {
    flex-direction: column;
  }
}

footer {
  background-color: #f0f0f0;
  padding: 2rem 0;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.social-links a {
  color: #6200ea;
  font-size: 2.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
  color: #3700b3;
  transform: scale(1.1);
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.footer-links a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #6200ea;
}

.copyright {
  font-size: 0.9rem;
  color: #666;
}
</style>
